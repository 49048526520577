<mat-card appearance="outlined" class="login-card">
  <mat-card-header>
      <mat-card-title>
          <h2>
              {{'auth.forgotPassword.title.fullLabel' | translate}}
          </h2>
      </mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <form *ngIf="!resetPasswordSent"
          [formGroup]="resetForm" (ngSubmit)="onSubmit()">
          <div class="reset-field">
              <mat-form-field>
                  <mat-label>{{'auth.forgotPassword.username.fullLabel' | translate}}</mat-label>
                  <mat-icon matPrefix>person</mat-icon>
                  <input matInput type="text" formControlName="username">
                  <mat-error>{{'auth.forgotPassword.requiredUsername.fullLabel' | translate}}</mat-error>
              </mat-form-field>
          </div>

          <button *ngIf="!isLoading"
              type="submit" mat-raised-button color="accent"
              [disabled]="resetForm.invalid || isLoading">{{'auth.forgotPassword.submit.fullLabel' | translate}}</button>
          <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent" ></mat-spinner>
      </form>
      <div *ngIf="resetPasswordSent">
          <p>{{'auth.forgotPassword.changePasswordMail.summary' | translate}}</p>
      </div>
  </mat-card-content>
</mat-card>