<form [formGroup]="languageForm" class="language-form" *ngIf="!isLoading && languageList">
  <mat-form-field>
      <mat-label>{{ 'main.languageSelector.shortLabel' | translate }}</mat-label>
      <mat-select name="lang" formControlName="lang">
          <mat-option class="language-selector-select-language" *ngFor="let language of languageList" [value]="language">
              {{ language.codeISO }}
          </mat-option>
      </mat-select>
  </mat-form-field>
</form>
