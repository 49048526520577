import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { WorkflowService } from '@services/workflow.service';
import { ScenarioService } from '@services/scenario.service';

import { Monitoring } from '@classes/workflow/monitoring';

@Injectable()
export class MonitoringResolver  {
    constructor(
        private uiSrv: UiService,
        private workflowSrv: WorkflowService,
        private scenarioSrv: ScenarioService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Monitoring> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.workflowSrv.getMonitoring()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
