// Import material theming functions
@use '@angular/material' as mat;
// @import '@angular/material/theming';
@import 'thottheme';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$app-primary: mat.m2-define-palette($thot-primary, 500, 100, 900);
$app-accent:  mat.m2-define-palette($thot-accent, 500, 300, 900);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette, A400, A200, A700);
$app-success: mat.m2-define-palette(mat.$m2-green-palette);
$app-info: mat.m2-define-palette(mat.$m2-blue-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
$app-theme: mat.m2-define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn
    )
));


// Create your Sass color vars (will be available in all the project)
$primary: mat.m2-get-color-from-palette($app-primary);
$primary-light: mat.m2-get-color-from-palette($app-primary, lighter);
$primary-dark: mat.m2-get-color-from-palette($app-primary, darker);

$accent: mat.m2-get-color-from-palette($app-accent);
$accent-light: mat.m2-get-color-from-palette($app-accent, lighter);
$accent-dark: mat.m2-get-color-from-palette($app-accent, darker);

$warn: mat.m2-get-color-from-palette($app-warn);
$warn-light: mat.m2-get-color-from-palette($app-warn, lighter);
$warn-dark: mat.m2-get-color-from-palette($app-warn, darker);

$success: mat.m2-get-color-from-palette($app-success);
$success-dark: mat.m2-get-color-from-palette($app-success, darker);
$success-light: mat.m2-get-color-from-palette($app-success, lighter);

$info: mat.m2-get-color-from-palette($app-info);
$info-dark: mat.m2-get-color-from-palette($app-info, darker);
$info-light: mat.m2-get-color-from-palette($app-info, lighter);

$contrast-color: #fff;
$border: 1px solid rgba(0, 0, 0, 0.12);
// $accent-transparent: #5bb4da3b
// $accent-transparent: #1591ca25
$flexmonster-selection: #79ccff33;
