@import 'variables';

.main-nav-item,
.main-nav-panel {
  border-bottom: 1px solid $primary-light;
  border-radius: 0 !important;
  background-color: $primary-dark!important;
}

.mat-mdc-list-base .main-nav-item,
.main-nav-panel .mat-expansion-panel-header-title {
  color: $primary-light;
  outline: none;

  &.active {
    color: $accent;
  }
}

.mat-mdc-list-base {
  .main-nav-item.little {
    font-size: 0.8em;
  }

  // .main-nav-item.active {
  //     background: $accent !important;
  //     color: $contrast-color;
  // }
}

.main-nav-panel {
  &.mat-expansion-panel-spacing {
    margin: 0 !important;
    margin-top: 8px;
  }

  .main-nav-item {
    border: none;
  }

  .mat-expansion-panel-header {
    padding: 0 16px !important;
    font-size: 16px;
  }

  .mat-expansion-panel-header-title {
    align-items: center;
  }

  .mat-expansion-indicator::after {
    color: $primary-light;
  }

  .mat-expansion-panel-body {
    padding: 0 0 16px !important;

    .mat-mdc-list-base {
      padding-top: 0;

      .main-nav-item .main-nav-item-content {
        padding-left: 20px;
      }
    }
  }
}

.mat-icon+.link-label {
  display: inline-block;
  margin-left: 16px;
}

.router-link-active {
  color: $accent !important;
}

.child {
  border-bottom: 0 !important;
  padding-left: 4px !important;
}

.active-link {
  color: $accent !important;
}