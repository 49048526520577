<div class="scenario-select">
    <mat-form-field>
        <mat-label> {{'agent.agentDetail.events.scenario.shortLabel' | translate}} </mat-label>
        <mat-select [formControl]="scenarioSelect">
            <mat-option class="agent-detail-events-select-scenario" *ngFor="let scenario of scenarios"
                        [value]="scenario">
                {{scenario.story.label}} ({{scenario.referenceMonth | date: 'MM-YYYY'}})
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-checkbox class="include-deleted" [checked]="includeDeleted"
                  (change)="toggleDeleted($event)"
                  *ngIf="selectedSCenario">
        {{'scenarios.events.toolbar.displayDeletedEvents.fullLabel' | translate}}
    </mat-checkbox>
    <mat-spinner [diameter]="24"
                 *ngIf="isLoading"></mat-spinner>
</div>

<div *ngFor="let refSituation of refsituationByContract"
     class="ref-situation">
    <h3> {{'agent.agentDetail.events.contract.shortLabel' | translate}} {{refSituation.refSituation.contract.docNumber}}
    </h3>
    <div>
        <h4> {{'agent.agentDetail.events.refSituation.shortLabel' | translate}} </h4>
        <app-ref-situation [refSituation]="refSituation.refSituation"
                           [indexValue]="indexValue"></app-ref-situation>
        <h4> {{'agent.agentDetail.events.title.shortLabel' | translate}} </h4>
        <app-events-table [events]="refSituation.events"
                          [restrictedMode]="true"
                          [indexValue]="indexValue"></app-events-table>
    </div>
</div>