<mat-toolbar>
    <mat-toolbar-row>
        <div *ngIf="screenSize > screenSizes.small; else shortMonths">
            <span *ngIf="isAuth && refMonth"
                  class="ref-month">
                {{'main.header.refMonth.fullLabel' | translate}} : {{refMonth}}
            </span>
            <span class="separator"
                  *ngIf="isAuth && lastNormalizedMonth">|</span>
            <span *ngIf="isAuth && lastNormalizedMonth"
                  class="normalized-month">
                {{'main.header.lastNormalizedMonth.fullLabel' | translate}} : {{lastNormalizedMonth}}
            </span>
        </div>
        <ng-template #shortMonths>
            <span *ngIf="isAuth && refMonth"
                  class="ref-month">
                {{'main.header.refMonth.shortLabel' | translate}} : {{refMonth}}
            </span>
            <span class="short-separator"
                  *ngIf="isAuth && lastNormalizedMonth">|</span>
            <span *ngIf="isAuth && lastNormalizedMonth"
                  class="normalized-month">
                {{'main.header.lastNormalizedMonth.shortLabel' | translate}} : {{lastNormalizedMonth}}
            </span>
        </ng-template>
        <div class="actions">
            <!-- <app-report-list></app-report-list> -->
            <a mat-button
               color="accent"
               *ngIf="isAuth"
               target="_blank"
               routerLink="wiki/file">
                <mat-icon>help_outline</mat-icon>
                <span *ngIf="screenSize > screenSizes.small"> Wiki </span>
            </a>
            <button mat-button
                    color="accent"
                    *ngIf="isAuth"
                    [matMenuTriggerFor]="menu"
                    class="userButton">
                <mat-icon>person</mat-icon>
                <ng-container *ngIf="screenSize > screenSizes.xsmall">
                    <span class="user"> {{ authUser.name }} </span>
                    <span class="user"
                          *ngIf="authUser.client">| {{ authUser.client }}</span>
                    <mat-icon class="user">arrow_drop_down</mat-icon>
                </ng-container>
            </button>
            <mat-menu #menu="matMenu">
                <div class="profile"
                     (click)="$event.stopPropagation()">
                    <span>{{'main.header.loggedAs.fullLabel' | translate}}</span>
                    <span>{{authUser?.profile}}</span>
                </div>
                <ng-container *ngIf="isAuth">
                    <a mat-menu-item
                       routerLink="/user/client-select"
                       routerLinkActive="active">
                        <mat-icon aria-label="settings">settings</mat-icon>
                        {{ 'main.header.clientSelector.shortLabel' | translate }}
                    </a>
                    <button mat-menu-item
                            (click)="redirectToHangfire()"
                            *ngIf="appRights?.canAccessHangfire">
                        <mat-icon aria-label="settings">settings</mat-icon>
                        Hangfire
                    </button>
                </ng-container>
                <button mat-menu-item
                        (click)="onLogout()">
                    <mat-icon>exit_to_app</mat-icon>
                    {{'main.header.logout.shortLabel' | translate}}
                </button>
            </mat-menu>
            <app-language-selector *ngIf="!isAuth"></app-language-selector>
        </div>
        <button mat-icon-button
                color="accent"
                (click)="onToggleSidenav()"
                *ngIf="screenSize <= screenSizes.medium">
            <mat-icon aria-label="Menu">menu</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>