import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { PrestationService } from '@services/prestation.service';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { PrestationOptions } from '@interfaces/prestation-options';

import { Agent } from '@classes/agent/agent';
import { Contract } from '@classes/contract/contract';
import { Prestation } from '@classes/prestation/prestation';
import { Pagination } from '@interfaces/pagination';

@Component({
    selector: 'app-detail-prestation',
    templateUrl: './detail-prestation.component.html',
    styleUrls: ['./detail-prestation.component.scss']
})
export class DetailPrestationComponent extends SubscriptionBaseComponent implements OnChanges {
    @Input() selectedAgent: Agent

    prestations: Prestation[];
    dataSource: MatTableDataSource<Prestation>;
    displayedColumns = [
        'contractId',
        'dueMonth',
        'periodStartdate',
        'periodEnddate',
        'fteValue',
        'prestationTypeId'
    ];

    pageIndex: number = 1;
    pageSize = 10;
    pagination: Pagination;
    pageLoading: boolean;
    baseFilter: string;
    filters: string;
    sorts: string;

    constructor(
        private prestationSrv: PrestationService
    ) {
        super();
    }

    ngOnChanges(): void {
        if (this.selectedAgent) {
            this.selectedAgent.contracts = this.selectedAgent.contracts.map(c => Contract.fromObject(c));
        }
        this.initSubscription();
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.sorts = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
        } else {
            this.sorts = '';
        }
        const options: PrestationOptions = {
            filters: this.filters,
            sorts: this.sorts
        }
        this.pageLoading = true;
        // this.prestationSrv.get(this.pageIndex, this.pageSize, options).subscribe(() => this.pageLoading = false);
        this.getPrestations(this.pageIndex, this.pageSize, options);
    }

    onPagechange(event: PageEvent): void {
        this.pageLoading = true;
        this.pageIndex = event.pageIndex + 1;
        const options: PrestationOptions = {
            filters: this.filters,
            sorts: this.sorts
        }
        this.getPrestations(this.pageIndex, this.pageSize, options);
    }

    onFilter(filterValue: string): void {
        this.filters = filterValue ? `${this.baseFilter},${filterValue}` : `${this.baseFilter}`;
        const options: PrestationOptions = {
            filters: this.filters,
            sorts: this.sorts
        }
        this.getPrestations(this.pageIndex, this.pageSize, options);
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource<Prestation>(this.prestations);
    }

    private getPrestations(pageIndex: number, pageSize: number, options: PrestationOptions): void {
        this.prestationSrv.get(pageIndex, pageSize, options).subscribe((prestationsFromSrv: HttpResponse<Prestation[]>) => {
            this.prestations = prestationsFromSrv.body;
            this.pagination = JSON.parse(prestationsFromSrv.headers.get('x-pagination'));
            if (this.prestations?.length) {
                this.initTable();
            } else {
                this.dataSource = new MatTableDataSource<Prestation>([]);
            }
            this.pageLoading = false;
        });
    }

    private initSubscription(): void {
        // this.subscription.add(
        //     this.prestationSrv.prestationsSubject.subscribe((prestationsFromSrv: HttpResponse<Prestation[]>) => {
        //         this.prestations = prestationsFromSrv.body;
        //         this.pagination = JSON.parse(prestationsFromSrv.headers.get('x-pagination'));
        //         if (this.prestations?.length) {
        //             this.initTable();
        //         } else {
        //             this.dataSource.data = [];
        //         }
        //     })
        // );

        this.baseFilter = `agentId==${this.selectedAgent.id}`;
        this.filters = `agentId==${this.selectedAgent.id}`;
        const options: PrestationOptions = {
            filters: this.filters
        }
        this.getPrestations(1, this.pageSize, options);
        // this.prestationSrv.get(1, this.pageSize, options).subscribe((prestationsFromSrv: HttpResponse<Prestation[]>) => {
        //     this.prestations = prestationsFromSrv.body;
        //     this.pagination = JSON.parse(prestationsFromSrv.headers.get('x-pagination'));
        //     if (this.prestations?.length) {
        //         this.initTable();
        //     } else {
        //         this.dataSource.data = [];
        //     }
        // });
    }

}
