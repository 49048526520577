import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { environment } from '@environments/environment';


import { AuthService } from '@services/auth.service';
import { NotificationsService } from '@services/notifications.service';
import { ReferenceMonthService } from '@services/reference-month.service';

import { NotificationType } from '@enums/notifications/notification-type.enum';

import { AppRights } from '@classes/app-rights';
import { AuthUser } from '@classes/auth/auth-user';
import { NavLink } from '@classes/nav-link';
import { ScreenSize } from '@enums/screen-size.enum';
import { SignalrNotifcation } from '@interfaces/notifications/signalrNotifcation';
import { AppRightsService } from '@services/app-rights.service';
import { UiService } from '@services/ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { DateTimeFormat } from '@classes/date-time-format';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isAuth: boolean;
    @Output() sidenavToggle = new EventEmitter<void>();

    appRights: Partial<AppRights>;

    screenSize: ScreenSize;
    screenSizes = ScreenSize;
    hideMenu: boolean;

    hangfireUrl = `${environment.apiUrl}hangfire?access_token=${localStorage.getItem('bizz-thot-token')}`;

    authUser: AuthUser;
    menuLinks: NavLink[];

    refMonth: string;
    lastNormalizedMonth: string;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private uiSrv: UiService,
        private notifSrv: NotificationsService,
        private refMonthSrv: ReferenceMonthService,
        private authSrv: AuthService,
        private cdr: ChangeDetectorRef,
        private appRightsSrv: AppRightsService,
    ) { }

    ngOnInit() {
        this.initSubscription();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isAuth.currentValue !== changes.isAuth.previousValue) {
            this.authUser = this.authSrv.getAuthUser();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    redirectToHangfire(): void {
        this.authSrv.refreshToken().subscribe(() => {
            window.open(this.hangfireUrl, "_blank");
        })
    }

    onLogout() {
        this.authSrv.logout();
    }

    onToggleSidenav(): void {
        this.sidenavToggle.emit();
    }

    private initSubscription(): void {
        this.appRightsSrv.appRights$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((appRights: AppRights) => {
                this.appRights = {
                    canAccessHangfire: appRights?.canAccessHangfire
                }
            });


        this.uiSrv.screenSize$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((screenSize: ScreenSize) => {
                this.screenSize = screenSize;
            });

        this.notifSrv.notificationsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((notification: SignalrNotifcation) => {
                if (notification?.type === NotificationType.RefMonthActivated) {
                    this.refMonthSrv.getRefMonth().subscribe(() => this.cdr.detectChanges());
                } else if (notification?.type === NotificationType.RefMonthCreated) {
                    this.refMonthSrv.getLastNormalized().subscribe(() => this.cdr.detectChanges());
                }
            });

        this.refMonthSrv.refMonthSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((refMonthFromSrv: string) => {
                this.refMonth = DateTime.fromISO(refMonthFromSrv).toFormat(DateTimeFormat.monthDash);
            });

        this.refMonthSrv.lastNormalizedMonthSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((monthFromSrv: string) => {
                this.lastNormalizedMonth = DateTime.fromISO(monthFromSrv).toFormat(DateTimeFormat.monthDash);
            });

        this.authSrv.authChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(isAuth => {
                this.isAuth = isAuth;
            });
    }

}
