<div class="timeline" *ngIf="contracts?.length">
    <button mat-icon-button color="accent" class="backward-year smaller-icon-button" (click)="previousYear()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <ng-container *ngFor="let year of years">
        <span class="year"> {{year}} </span>
    </ng-container>
    <button mat-icon-button class="forward-year smaller-icon-button" (click)="nextYear()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>

    <div class="separator">
        <div *ngFor="let year of years"></div>
    </div>

    <div *ngFor="let contract of contracts" class="contract">
        <span [matTooltip]="getDates(contract)">
            {{'agent.agentDetail.timeline.contract.shortLabel' | translate}} {{contract.docNumber}}
        </span>
        <div *ngFor="let year of years"
            appTimelineContract [startDate]="contract.startDate" [endDate]="contract.endDate" [year]="year"
            class="contract-year"></div>
    </div>
</div>