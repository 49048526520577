import { LinkDisplay } from '@enums/link-display.enum';

import { NavLink } from '@classes/nav-link';
import { Roles } from './models/enums/profile/roles';


export let navLinks: NavLink[] = [
    { route: '/login', icon: "exit_to_app", display: LinkDisplay.unauth, title: 'auth.login.title' },
    { route: '/home', icon: "home", display: LinkDisplay.auth, title: 'home.title' },
    { route: '/story-line', icon: "date_range", display: LinkDisplay.auth, title: 'storyLine.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuStoryline] },
    { route: '/fte-encoding', icon: "insert_chart", display: LinkDisplay.auth, title: 'fteEncoding.title', roles: [Roles.adminEncodageDirecteur] },
    {
        route: '/mapping', icon: "account_tree", display: LinkDisplay.auth,
        title: 'mapping.title',
        children: [
            { route: '/mapping/payment-section', display: LinkDisplay.auth, title: 'mapping.paymentSection.title' },
            { route: '/mapping/professional-status', display: LinkDisplay.auth, title: 'mapping.professionalStatus.title' },
            { route: '/mapping/structure', display: LinkDisplay.auth, title: 'mapping.structure.title' },
        ],
        roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuMapping]
    },
    { route: '/estimator', icon: "insert_chart", display: LinkDisplay.auth, title: 'estimator.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuEstimateurs] },
    { route: '/agent', icon: "contacts", display: LinkDisplay.auth, title: 'agent.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuAgent] },
    {
        route: '/report', icon: "grid_on", display: LinkDisplay.auth,
        title: 'reports.title',
        roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuOutilDeRapportModeSimple, Roles.visuOutilDeRapportModeAvance, Roles.adminOutilsDeRapport, Roles.adminPlanDEmbauche, Roles.adminResponsabilisationPension],
        children: [
            { route: '/report/simple', display: LinkDisplay.auth, title: 'reports.simple.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuOutilDeRapportModeSimple] },
            { route: '/report/advanced', display: LinkDisplay.auth, title: 'reports.advanced.title', roles: [Roles.administrateurClient, Roles.visuOutilDeRapportModeAvance] },
            { route: '/report/expert', display: LinkDisplay.auth, title: 'reports.expert.title', roles: [Roles.adminOutilsDeRapport] },
            { route: '/report/customs', display: LinkDisplay.auth, title: 'reports.customs.title', roles: [Roles.administrateurClient, Roles.adminPlanDEmbauche, Roles.adminResponsabilisationPension] },
            { route: '/report/structure', display: LinkDisplay.auth, title: 'mapping.reports.title', roles: [Roles.adminOutilsDeRapport] },
            { route: '/report/layouts', display: LinkDisplay.auth, title: 'reports.layouts.title', roles: [Roles.adminOutilsDeRapport] },
            // { route: '/report/olap', display: LinkDisplay.auth, title: 'reports.olap.title', roles: [Roles.adminBizzDev] }
        ]
    },
    {
        route: '/licences', icon: "supervised_user_circle", display: LinkDisplay.auth,
        title: 'licences.users.title',
        roles: [
            Roles.adminUtilisateurs,
            Roles.visuUtilisateurs
        ],
        children: [
            { route: '/licences/users', display: LinkDisplay.auth, title: 'licences.users.title' },
        ]
    },
    {
        route: '/profiles', icon: "manage_accounts", display: LinkDisplay.auth,
        title: 'profiles.title',
        roles: [
            Roles.visuRoles
        ]
    },
    {
        route: '/organigram', icon: "account_tree", display: LinkDisplay.auth,
        title: 'organigram.title',
        roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuOrganigramme]
    },
    { route: '/upload', icon: 'cloud_upload', display: LinkDisplay.auth, title: 'files.title', roles: [Roles.administrateurClient, Roles.adminBudget, Roles.adminEvtsScenario] },
    {
        route: '/parameters', icon: 'settings', display: LinkDisplay.auth,
        title: 'parameters.title',
        roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuParametresConjoncturels, Roles.visuCodesFonctionnels, Roles.visuBaremeOfficiel, Roles.visuRemplacement, Roles.visuParametresConfigurationDesStrategies, Roles.visuGestionDesTags],
        children: [
            { route: '/parameters/conjonctural-parameters', display: LinkDisplay.auth, title: 'parameters.conjoncturalParameters.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuParametresConjoncturels] },
            { route: '/parameters/functional-article', display: LinkDisplay.auth, title: 'parameters.functionalCode.title', roles: [Roles.administrateurClient, Roles.visuCodesFonctionnels] },
            { route: '/parameters/scale-amount', display: LinkDisplay.auth, title: 'parameters.scaleAmount.title', roles: [Roles.administrateurClient, Roles.visuClient, Roles.visuBaremeOfficiel] },
            { route: '/parameters/replacements', display: LinkDisplay.auth, title: 'parameters.replacements.title', roles: [Roles.administrateurClient, Roles.visuRemplacement] },
            { route: '/parameters/substitution-scale-payment', display: LinkDisplay.auth, title: 'parameters.substitutionScalePayment.title', roles: [Roles.administrateurClient, Roles.visuParametresConfigurationDesStrategies] },
            { route: '/parameters/tags-management', display: LinkDisplay.auth, title: 'parameters.tags.title', roles: [Roles.administrateurClient, Roles.visuGestionDesTags] },
        ]
    },
    {
        route: '/process', icon: 'sync', display: LinkDisplay.auth,
        title: 'process.title',
        roles: [Roles.adminIntegration, Roles.visuProcess],
        children: [
            { route: '/process/extracted-file-status', display: LinkDisplay.auth, title: 'collect.extractedFile.extractedFileStatus.title', roles: [Roles.adminIntegration, Roles.visuProcess] },
            { route: '/process/file-template-configuration', display: LinkDisplay.auth, title: 'collect.fileTemplate.clientConfiguration.title', roles: [Roles.adminIntegration, Roles.visuProcess]  },
            { route: '/process/workflows-executions', display: LinkDisplay.auth, title: 'workflows.executions.title', roles: [Roles.adminIntegration]  },
        ]
    },
    {
        route: '/bizzdev', icon: "computer", display: LinkDisplay.auth,
        title: 'bizzdev.title',
        roles: [Roles.adminBizzDev],
        children: [
            { route: '/bizzdev/parameter', display: LinkDisplay.auth, title: 'parameter.title' },
            // { route: '/bizzdev/extracted-file-status', display: LinkDisplay.auth, title: 'collect.extractedFile.extractedFileStatus.title' },
            // { route: '/bizzdev/file-template-configuration', display: LinkDisplay.auth, title: 'collect.fileTemplate.clientConfiguration.title' },
            { route: '/bizzdev/query-extraction', display: LinkDisplay.auth, title: 'collect.queryExtraction.title' },
            { route: '/bizzdev/universe', display: LinkDisplay.auth, title: 'bizzdev.universes.title' },
            { route: '/bizzdev/text', display: LinkDisplay.auth, title: 'bizzdev.texts.title' },
            // { route: '/bizzdev/workflows-executions', display: LinkDisplay.auth, title: 'workflows.executions.title' },
            { route: '/bizzdev/logs', display: LinkDisplay.auth, title: 'bizzdev.logs.title' },
        ]
    },

    // { route: '/help', icon: "help", display: LinkDisplay.auth, title: 'help.title', roles: [RoleType.adm_bizzdev] }
];

