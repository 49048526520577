import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { AgentService } from '@services/agent.service';

import { Contract } from '@classes/contract/contract';

@Component({
    selector: 'app-agent',
    templateUrl: './agent.component.html',
    styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {
    selectedContracts: Contract[] = [];
    doesCompare: boolean;

    constructor(
        private agentSrv: AgentService
    ) { }

    ngOnInit() {
        this.agentSrv.resetSelectedAgents(true);
    }

    onSelectContract(contract: Contract, agentIndex: number): void {
        this.selectedContracts[agentIndex] = contract;
    }

    resetSelectedAgents(event: MatCheckboxChange): void {
        if (!event.checked) {
            this.agentSrv.resetSelectedAgents();
        }
    }
}
