<h1>{{'agent.title.fullLabel' | translate}}</h1>

<mat-card >
    <mat-card-header>
        <mat-checkbox [(ngModel)]="doesCompare" (change)="resetSelectedAgents($event)">
            {{ 'agent.compare.shortLabel' | translate }}
        </mat-checkbox>
    </mat-card-header>
    <mat-card-content>
        <section>
            <app-agent-detail [index]="0" (contractSelected)="onSelectContract($event, 0)"></app-agent-detail>
            <section *ngIf="selectedContracts[0]">
                <app-contract-state [contract]="selectedContracts[0]"></app-contract-state>
            </section>
        </section>
        <section *ngIf="doesCompare">
            <app-agent-detail [index]="1" (contractSelected)="onSelectContract($event, 1)"></app-agent-detail>
            <section *ngIf="selectedContracts[1]">
                <app-contract-state [contract]="selectedContracts[1]"></app-contract-state>
            </section>
        </section>
    </mat-card-content>
</mat-card>

