import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';

import { environment } from '@environments/environment';

export class ApiTranslateLoader implements TranslateLoader {
    private baseUrl = environment.apiUrl + 'text';

    constructor(
        private http: HttpClient
    ) { }

    getTranslation(lang: string): Observable<any> {
        const url = lang ? `${this.baseUrl}/${lang}` : this.baseUrl;
        if (environment.localTranslations) {
            return this.http.get('./assets/i18n/fr.json');
        }

        return this.http.get(url)
            .pipe(
                // return another observable from the local transaltions if the first call returns nothing
                switchMap(result => {
                    if (Object.keys(result).length) {
                        return of(result);
                    } else {
                        return this.http.get('./assets/i18n/fr.json');
                    }
                }),
                catchError(() => this.http.get('./assets/i18n/fr.json'))
            );
    }
}
