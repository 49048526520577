import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    MissingTranslationHandler,
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';

import { environment } from '@environments/environment';
import { ApiMissingTranslationHandler } from './shared/apiMissingTranslationHandler';
import { ApiTranslateLoader } from './shared/apiTranslationLoader';
import { errorHandlerProviders } from './shared/error-handler';
import { httpInterceptorProviders } from './shared/http-interceptors';

import { appInitializerProviders } from './shared/appInitializerProviders';
import { resolverProviders } from './shared/resolvers';

import { AgentModule } from './agent/agent.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HelpModule } from './help/help.module';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';

import { registerLocaleData } from '@angular/common';
import localeBe from '@angular/common/locales/be';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { CustomMatPaginator } from './shared/customMatPaginator';
registerLocaleData(localeBe, 'be');

export function tokenGetter(): string {
    return localStorage.getItem(environment.tokenKey);
}

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient): ApiTranslateLoader {
    return new ApiTranslateLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        // EstimatorComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AuthModule,
        HelpModule,
        AgentModule,
        MainModule,
        DashboardModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [environment.apiDomain],
                disallowedRoutes: [environment.identityApiUrl + 'connect/token']
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: ApiMissingTranslationHandler
            }
        }),
        FontAwesomeModule], providers: [
            httpInterceptorProviders,
            errorHandlerProviders,
            JwtInterceptor,
            resolverProviders,
            appInitializerProviders,
            {
                provide: MatPaginatorIntl, useClass: CustomMatPaginator
            },
            { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
