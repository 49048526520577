<form [formGroup]="agentForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label>{{'agent.selector.search.shortLabel' | translate}}</mat-label>
    <input matInput [matAutocomplete]="auto" formControlName="searchAgent" required />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="selectAgent($event.option.value)">
      <!-- <ng-container *ngIf="!isLoading && agents && agents.length"> -->
      <mat-option *ngFor="let agent of agents" [value]="agent" class="agent-selector-select-agent">
        <span>{{ agent.display }}</span>
      </mat-option>
      <!-- </ng-container> -->
    </mat-autocomplete>
  </mat-form-field>

  <!-- <ng-container *ngIf="selectedAgentId">
        <mat-checkbox formControlName="useMonth">
            {{ 'agent.selector.useMonth.shortLabel' | translate }}
        </mat-checkbox>

        <section *ngIf="useMonth">
            <mat-form-field>
                <mat-label>{{'agent.selector.from.shortLabel' | translate}}</mat-label>
                <mat-select formControlName="startMonth">
                    <mat-option *ngFor="let month of startMonths" [value]="month">
                        {{ month | date: dateFormat }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'agent.selector.to.shortLabel' | translate}}</mat-label>
                <mat-select formControlName="endMonth">
                    <mat-option *ngFor="let month of endMonths" [value]="month">
                        {{ month | date: dateFormat }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </ng-container> -->

  <button type="submit" mat-raised-button color="accent" [disabled]="!agentForm.valid">
    {{'agent.selector.submit.fullLabel' | translate}}
  </button>
</form>
<mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>