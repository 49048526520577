import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ChangePwdGuard  {
    constructor(
        private authSrv: AuthService
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if ((next.queryParams.token && next.queryParams.id
            && next.queryParams.exp && next.queryParams.user)) {
            return true;
        }

        if (this.authSrv.getDecodedToken()) {
            return true;
        }

        // this.uiSrv.showSnackbar("auth.changePassword.passwordResetError.summary", true, 5000);
        this.authSrv.logout();
        return false;
    }
}
