import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PreloadSelectedModules } from './shared/preloadSelectedModules';

import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  {
    path: 'story-line',
    loadChildren: () => import('./story-line/story-line.module').then(m => m.StoryLineModule),
    data: { preload: true }
  },
  { path: 'fte-encoding', loadChildren: () => import('./fte-encoding/fte-encoding.module').then(m => m.FteEncodingModule) },
  { path: 'mapping', loadChildren: () => import('./mapping/mapping.module').then(m => m.MappingModule) },
  { path: 'estimator', loadChildren: () => import('./estimator/estimator.module').then(m => m.EstimatorModule) },
  // {
  //   path: 'estimator', component: EstimatorComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     roles: [
  //       RoleType.adm_bizzdev,
  //       RoleType.adm_client
  //     ]
  //   }
  // },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    data: { preload: true }
  },
  { path: 'licences', loadChildren: () => import('./licences/licences.module').then(m => m.LicencesModule) },
  { path: 'profiles', loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule) },
  { path: 'organigram', loadChildren: () => import('./organigram/organigram.module').then(m => m.OrganigramModule) },
  { path: 'upload', loadChildren: () => import('./upload/upload.module').then(m => m.UploadModule) },
  { path: 'parameters', loadChildren: () => import('./parameters/parameters.module').then(m => m.ParametersModule) },
  { path: 'process', loadChildren: () => import('./process/process.module').then(m => m.ProcessModule) },
  { path: 'bizzdev', loadChildren: () => import('./bizzdev/bizzdev.module').then(m => m.BizzdevModule) },
  { path: 'wiki', loadChildren: () => import('./wiki/wiki.module').then(m => m.WikiModule) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  providers: [PreloadSelectedModules],
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadSelectedModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
